<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      head-title="检查基本信息"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain" style="padding: 0 12px">
        <el-form
          ref="dataForm"
          :model="dataForm"
          label-width="150px"
          :disabled="formType == 'view'"
          :rules="rules"
        >
          <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
          <el-row>
            <el-col :span="7">
              <el-form-item label="检查编号" prop="prjCode">
                <el-input
                  v-model="dataForm.prjCode"
                  placeholder="请输入项目编码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="自动生成" prop="prjCodeAuto">
                <el-switch v-model="prjCodeAuto"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="检查名称" prop="prjName">
                <el-input
                  v-model="dataForm.prjName"
                  placeholder="请输入项目名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="检查类型" prop="isDomestic">
                <el-select
                  v-model="dataForm.isDomestic"
                  placeholder="请选择境内外"
                >
                  <el-option
                    v-for="item in prjDomesticList"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictKey"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="发起人" prop="contractName">
                <el-input
                  v-model="dataForm.contractName"
                  placeholder="请填写承建单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label=""> </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="所属项目" prop="areName">
                <el-input
                  v-model="dataForm.areName"
                  placeholder="请填写项目区域"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="检查日期" prop="prjDate">
                <el-input
                  v-model="dataForm.prjDate"
                  placeholder="请填写立项日期"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="prjAs">
                <el-input
                  v-model="dataForm.prjAs"
                  placeholder="请填写项目简称"
                  maxlength="50"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="shuttleBackBox">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="检查项" name="corePersonnel">
              <el-container>
                <CommonTree
                  treeTitle="检查项分类"
                  isShowdig
                  @getTreeAdd="getTreeAdd"
                  @getTreeDelete="getTreeDelete"
                  @getTreeEdit="getTreeEdit"
                  :searchTitle="searchTitle"
                  :treeData="corePersonnelTreeData"
                  :defaultProps="defaultProps"
                  @getNodeClick="corePersonnelTreeNodeClick"
                  :showCheckbox="false"
                  :treeExpand="true"
                />
                <div class="table-box">
                  <head-layout
                    v-if="formType != 'view'"
                    :head-btn-options="corePersonnelHeadBtnOptions"
                    @handleImport="handleImport"
                    @head-export="headExport"
                    head-title="检查项"
                  ></head-layout>
                  <grid-layout
                    ref="gridLayOut"
                    :table-options="corePersonnelTableOption"
                    :table-data="corePersonnelTableData"
                    :table-loading="tableLoading"
                    @gird-handle-select-click="selectionChange"
                  >
                    <template #customBtn="{ row }" v-if="formType != 'view'">
                      <el-button
                        style="margin: 0 3px"
                        type="text"
                        size="small"
                        @click="rowdel(row)"
                        >删除
                      </el-button>
                    </template>
                  </grid-layout>
                </div>
              </el-container>
            </el-tab-pane>
            <el-tab-pane label="检查人员" name="thirdPartyOrganization">
              <head-layout
                v-if="formType != 'view'"
                head-title="检查人员"
                :head-btn-options="userBtnOptions"
                @head-add="headAdd"
                @head-remove="headRemove"
              >
              </head-layout>
              <grid-layout
                ref="gridLayOut"
                :table-options="thirdPartyOrganizationTableOption"
                :table-data="thirdPartyOrganizationTableData"
                :table-loading="tableLoading"
                @gird-handle-select-click="selectionChange"
              >
              </grid-layout>
            </el-tab-pane>
            <el-tab-pane label="相关资料 " name="thirdPartyPersonnel">
              <head-layout
                v-if="formType != 'view'"
                head-title="资料清单"
                :head-btn-options="fileBtnOptions"
                @headImport="headImport"
              >
                <el-button size="mini" slot="right">引用</el-button>
              </head-layout>
              <el-upload disabled :file-list="fileList" class="upload-demo">
              </el-upload>
            </el-tab-pane>
            <el-tab-pane label="问题清单" name="taskList">
              <head-layout
                v-if="formType != 'view'"
                head-title=""
              ></head-layout>
              <grid-layout
                ref="gridLayOut"
                :table-options="corePersonnelTableOption"
                :table-data="thirdPartyPersonnelTableData"
                :table-loading="tableLoading"
                @gird-handle-select-click="selectionChange"
              >
                <template #customBtn="{ row }" v-if="formType != 'view'">
                  <el-button
                    style="margin: 0 3px"
                    type="text"
                    size="small"
                    @click="rowdel(row)"
                    >删除</el-button
                  >
                </template>
              </grid-layout>
            </el-tab-pane>
            <el-tab-pane
              label="整改通知书"
              v-if="formType == 'view'"
              name="Rectify"
            >
              <el-container>
                <el-upload disabled :file-list="fileList" class="upload-demo">
                </el-upload>
              </el-container>
            </el-tab-pane>
            <el-tab-pane
              label="处罚通知书"
              v-if="formType == 'view'"
              name="punishment"
            >
              <el-container>
                <el-upload disabled :file-list="fileList" class="upload-demo">
                </el-upload>
              </el-container>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <el-upload
      v-show="hideUpload"
      class="upload-demo"
      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
      :show-file-list="false"
      :on-success="handleSuccess"
      ref="uploadRef"
      :headers="headers"
    ></el-upload>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
    <el-dialog
      title="检查分类导入"
      append-to-body
      :visible.sync="excelBox"
      width="555px"
    >
      <div class="import">
        <avue-form
          :option="excelOption"
          v-model="excelOption.excelForm"
          :upload-after="uploadAfter"
        >
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import mixins from "./mixins";
import { prjbasicinfoDetail, prjbasicinfoSubmit } from "@/api/safe/itemList";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import { getToken } from "@/util/auth";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";

export default {
  mixins: [mixins],
  components: {
    UserDetpDialog,
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      fileList: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
        {
          name: "food2.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      deptShow: false,
      hideUpload: false,
      excelBox: false,
    };
  },
  computed: {
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
  },
  mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      this.headTitle = "项目配置";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    }
    if (type == "view") {
      this.headTitle = "查看项目配置";
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    }
  },
  methods: {
    //导入
    handleImport() {
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.onLoad(this.page);
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/thirdcompany/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "检查分类模板" + ".xlsx");
      });
    },
    // 导出
    handleExport() {
      this.$confirm("是否导出检查分类数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        exportBlob(
          `/api/sinoma-hse-prj/thirdcompany/export?companyCode=${
            this.$refs.gridHeadLayout.searchForm.companyCode
              ? this.$refs.gridHeadLayout.searchForm.companyCode
              : ""
          }&isUsed=${
            this.$refs.gridHeadLayout.searchForm.isUsed
              ? this.$refs.gridHeadLayout.searchForm.isUsed
              : ""
          }&curDate=${
            this.$refs.gridHeadLayout.searchForm.curDate
              ? this.$refs.gridHeadLayout.searchForm.curDate
              : ""
          }&${this.website.tokenHeader}=${getToken()}`
        ).then((res) => {
          downloadXls(res.data, `检查分类${dateNow()}.xlsx`);
        });
      });
    },
    getTreeAdd() {},
    getTreeDelete() {},
    getTreeEdit() {},
    handleClick(tab) {},
    selectData(row) {},
    headAdd() {
      this.deptShow = true;
    },
    headRemove() {},
    corePersonnelTreeNodeClick() {},
    headImport() {
      console.log(111);
      this.$refs["uploadRef"].$children[0].$refs.input.click();
    },
    //批量选择
    selectionChange(list) {
      this.selectionList = list;
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          prjbasicinfoSubmit(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              if (boolean) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              }
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$loading().close();
            }
          });
        }
      });
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      prjbasicinfoDetail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
        }
      });
    },

    // 删除上传文件
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link,
          });
        } else {
          list.push({
            name: i.name,
            url: i.url,
          });
        }
      });
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let list = fileList.map((i) => {
        if (i.response) {
          return {
            name: i.name,
            extension: i.response.data.type,
            id: i.response.data.attachId,
            link: i.response.data.link,
          };
        } else {
          return {
            name: i.name,
            extension: i.type,
            id: i.attachId,
            link: i.link,
          };
        }
      });
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  .el-tabs {
    width: 100%;
  }
  .upload-demo {
    background-color: #ffffff;
    padding-left: 10px;
  }
  .table-box {
    width: calc(100% - 280px);
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
</style>
